import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import PricingOption from '../../components/PricingOption';
import AnimatedBlob from '../../components/AnimatedBlob';
import Loader from '../../components/Loader';
import './style.scss';

var free = {
    name: 'Free',
    cost: 'Free',
    users: 1,
    contacts: 25,
    gradientClass: 'pink',
    emailTemplates: '(Limited)',
};

var warmUp = {
    name: 'Warm-Up',
    cost: '99',
    additionalUserPrice: 20,
    users: 1,
    contacts: 'Unlimited',
    gradientClass: 'orange',
    emailTemplates: '(Starter Pack)',
};

var contender = {
    name: 'Contender',
    cost: '249',
    additionalUserPrice: 25,
    users: 3,
    contacts: 'Unlimited',
    collateralBuilder: true,
    marketingCollateral: true,
    marketingCollateralDescription: '(Whitepapers, eBooks)',
    landingPageBuilder: true,
    gradientClass: 'purple',
    emailTemplates: '(Full Library)',
};

var pro = {
    name: 'Pro',
    cost: '499',
    users: 'Unlimited',
    contacts: 'Unlimited',
    emailTemplates: '(Full Library)',
    collateralBuilder: true,
    marketingCollateral: 'Plus',
    marketingCollateralDescription:
        '(Whitepapers, eBooks, Landing Pages & Videos)',
    landingPageBuilder: true,
    marketingPlans: true,
    journeys: true,
    sms: true,
    premiumSupport: '(Get help setting up your first year of marketing)',
    calling: true,
    proposalBuilder: true,
    mostPopular: true,
    gradientClass: 'blue',
};

const Pricing = () => {
    useEffect(() => {});

    return (
        <Layout>
            <Seo title="Pricing" />
            <div className="pricing-page">
                <Container className="grey-blob container-1">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelectorAll('.pricing-option')
                                .forEach(card => card.classList.add('animate'));
                        }}
                    />
                    <Row>
                        <Col className="centered-text">
                            <h1>Get started with GlassHive</h1>
                            <p className="hero-subtext">
                                Try it for free and then dial it to your
                                company’s needs
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-2">
                    <AnimatedBlob
                        cls="canvas-1"
                        fill="rgba(121, 91, 255, .25)"
                    />
                    <Row className="pricing-options">
                        <PricingOption option={free} />
                        <PricingOption option={warmUp} />
                        <PricingOption option={contender} />
                        <PricingOption option={pro} />
                    </Row>
                    <Row className="pricing-key">
                        <p>
                            <span className="asteriks">*</span>= Coming soon
                        </p>
                    </Row>
                    <AnimatedBlob
                        cls="canvas-2"
                        fill="rgba(251, 96, 157, .25)"
                    />
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col className="">
                            <form
                                id="pricing-form"
                                className="pink-form"
                                method="POST"
                                name="Pricing Form"
                                action="/pricing/#thanks"
                                encType="multipart/form-data"
                                netlify="true"
                                netlify-honeypot="bot-field"
                                data-category="Consultation"
                                onSubmit={e => {
                                    e.preventDefault();
                                    const submitButton = document.getElementById(
                                        'sbmt-form-btn'
                                    );
                                    const loader = document.querySelector(
                                        '.loader'
                                    );
                                    const formName = document.getElementById(
                                        'pricing-form'
                                    );

                                    loader.style.display = 'block';
                                    submitButton.style.display = 'none';

                                    fetch(formName.getAttribute('action'), {
                                        method: 'POST',
                                        body: new FormData(
                                            document.getElementById(
                                                'pricing-form'
                                            )
                                        ),
                                    }).then(res => {
                                        if (res.status === 200) {
                                            document.querySelector(
                                                '#pricing-form'
                                            ).style.display = 'none';
                                            document.querySelector(
                                                '.contact-thank-you'
                                            ).style.display = 'block';
                                        } else {
                                            loader.style.display = 'none';
                                            document.getElementById(
                                                'error-msg'
                                            ).style.display = 'block';
                                            submitButton.style.display =
                                                'block';
                                        }
                                    });
                                }}
                            >
                                <input
                                    type="hidden"
                                    name="bot-field"
                                    id="bot"
                                />
                                <input
                                    type="hidden"
                                    name="form-name"
                                    value="pricing-form"
                                />
                                <div className="form-container-for-btn">
                                    <div className="top">
                                        <h3 className="white-text form-title">
                                            Need a GlassHive vendor or agency
                                            account? Email us to get your custom
                                            quote!
                                        </h3>
                                    </div>
                                    <div className="what-do-we-call-you">
                                        <div className="field name-field">
                                            <label htmlFor="firstName">
                                                What’s your name?
                                            </label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                id="firstName"
                                                placeholder="Name"
                                                required
                                            ></input>
                                        </div>

                                        <div className="field">
                                            <label
                                                className="second-label"
                                                htmlFor="company"
                                            >
                                                What company are you with?
                                            </label>
                                            <input
                                                type="text"
                                                name="company"
                                                placeholder="Company"
                                                id="company"
                                            ></input>
                                        </div>
                                        <div className="field email-field">
                                            <label htmlFor="email">
                                                What’s your email address?
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Email"
                                                required
                                            ></input>
                                        </div>
                                    </div>

                                    <div className="field comment-field">
                                        <label
                                            className="textarea-label"
                                            htmlFor="message"
                                        >
                                            What question can we answer for you?
                                        </label>
                                        <textarea
                                            type="text"
                                            name="message"
                                            id="message"
                                            placeholder="Comments"
                                        ></textarea>
                                    </div>
                                    <div className="hide-me field">
                                        <input
                                            data-form-type="Consultation"
                                            className="formcat"
                                            hidden
                                        ></input>
                                    </div>
                                </div>
                                <div className="submit-btn">
                                    <Loader />
                                    <p id="error-msg" style={{ color: 'red' }}>
                                        Looks like there was a problem
                                        submitting your form. Please ensure ALL
                                        form fields are filled out and try
                                        again.
                                    </p>
                                    <button
                                        type="submit"
                                        className="btn blue first"
                                        id="sbmt-form-btn"
                                    >
                                        <span className="btn__mask"></span>
                                        <span className="btn__text">
                                            Send Email
                                        </span>
                                    </button>
                                </div>
                            </form>
                            <div
                                className="contact-thank-you reach"
                                id="thanks"
                            >
                                <h5>
                                    Thank you for contacting us. We'll be in
                                    touch shortly!
                                </h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Pricing;
