import React from "react"
import ShinyButton from "../ShinyButton"
import { throwSignupConversionEvent } from '../../scripts/utils';

import "./style.scss"

const PricingOption = ({ option }) => (
  <div className={`pricing-option " ${option.name.toLowerCase()} ${option.mostPopular ? 'most-popular' : ''}`}>
    <div className={`top reach ${option.gradientClass}`}>
      <h4 className="white-text">{option.name}</h4>
    </div>
    <div className="parts">
      <p className="price">
      {option.cost != 'Free' && <sup className="dollar-sign">$</sup>}{option.cost}{option.cost != 'Free' && <span className="per-month">/mo</span>}
      </p>
      <div className="additional-users-container"> 
        {option.additionalUserPrice && <span className="additional-users">+${option.additionalUserPrice} / additional users</span>}
      </div>
      <ul>
        <li className="included">{option.users} {option.users > 0 && ' Free'} User{(option.users === 'Unlimited' || option.users > 1) && 's'}</li>
        <li className="included">CRM</li>
        <li className="included">Email Builder</li>
        <li className="included flex-col">Email Campaign Templates {option.emailTemplates}</li>
        <li className="included">Marketing Module</li>
        <li className="included">Sales Module</li>
        <li className="included">Marketing & Sales Reports</li>
        <li className="included">Tasking</li>
        <li className="included">Integrations</li>
        <li className="included">{option.contacts} Contacts</li>
        <li className={`${option.marketingCollateral ? 'included' : ''} flex-col`}>Marketing Collateral {option.marketingCollateral} <span className="additional-text">{option.marketingCollateralDescription}</span></li>
        <li className={`${option.collateralBuilder ? 'included' : ''}`}>Collateral Builder {option.collateralBuilder}</li>
        <li className={`${option.landingPageBuilder ? 'included' : ''} coming-soon`} >Landing Page Builder</li>
        <li className={`${option.marketingPlans ? 'included' : ''}`}>Marketing Plans</li>
        <li className={`${option.journeys ? 'included' : ''}`}>Journeys (Automations)</li>
        <li className={`${option.premiumSupport ? 'included' : ''} flex-col`}>Premium Support <span className="additional-text">{option.premiumSupport}</span></li>
        <li className={`${option.sms ? 'included' : ''} coming-soon`}>SMS</li>
        <li className={`${option.calling ? 'included' : ''} coming-soon`}>Calling</li>
        <li className={`${option.proposalBuilder ? 'included' : ''} coming-soon`}>Proposal Builder</li>
      </ul>
      <ShinyButton
        className="btn pink create-free-account-button"
        onClick={() =>
            throwSignupConversionEvent('https://app.glasshive.com/Account/Login?s=signup')
        }
      >
        Sign Up
      </ShinyButton>
    </div>
  </div>
)

export default PricingOption
